const tabs = {
    get_started: 'Commencez',
    dashboard: 'Tableau de bord',
    applications: 'Applications',
    api_resources: 'Ressources API',
    sign_in_experience: 'Expérience de connexion',
    connectors: 'Connecteurs',
    users: 'Gestion des utilisateurs',
    audit_logs: "Journaux d'audit",
    roles: 'Roles',
    docs: 'Documentation',
    contact_us: 'Contactez nous',
    settings: 'Paramètres',
};
export default tabs;
