@use '@/scss/underscore' as _;

.content {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}

.main {
  flex-grow: 1;
  padding: 0 _.unit(2);
  overflow-y: scroll;

  > * {
    @include _.main-content-width;
  }

  // App Insights wrapper on cloud env prevents the CSS assignment to direct children
  [class='appInsightsWrapper'] > * {
    @include _.main-content-width;
  }
}
