@use '@/scss/underscore' as _;

.icon {
  flex-shrink: 0;
}

.pagination {
  margin-top: _.unit(4);
}

.applicationName {
  width: 360px;
}
